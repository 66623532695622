define("discourse/plugins/hosted-site/discourse/connectors/admin-api-keys/admin-api-keys-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("needToUpgrade", component.siteSettings.hide_admin_api_keys);
    }
  };
});