define("discourse/plugins/hosted-site/discourse/templates/connectors/admin-api-keys/admin-api-keys-override", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.needToUpgrade}}
    {{api-keys-upsell}}
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "bgG/cPBc",
    "block": "[[[41,[30,0,[\"needToUpgrade\"]],[[[1,\"  \"],[41,[28,[32,0],[\"api-keys-upsell\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"api-keys-upsell\"],[[28,[32,1],[\"api-keys-upsell\"],null]]]]],[[[1,[52,[30,1,[\"api-keys-upsell\"]],[28,[30,1,[\"api-keys-upsell\"]],null,null],[28,[32,2],[[30,0],\"api-keys-upsell\",\"[\\\"The `api-keys-upsell` property path was used in the `discourse/plugins/hosted-site/discourse/templates/connectors/admin-api-keys/admin-api-keys-override.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.api-keys-upsell}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"maybeHelpers\",\"&default\"],false,[\"if\",\"api-keys-upsell\",\"let\",\"hash\",\"yield\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/templates/connectors/admin-api-keys/admin-api-keys-override.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});